@import "./global";

.footer {
  background: var(--footer-bg);
  padding: 20px 0 18px;
  padding-top: 48px;
  &__icon {
    color: var(--text-color);
    font-size: 20px;
  }
  &_top {
    display: flex;
    justify-content: space-between;

    &_cards {
      width: 22%;

      &_info {
        color: var(--dark-gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 10px;
      }

      &_title {
        margin-top: 15px;
        color: var(--text-color);
      }

      &_text {
        color: var(--text-color);
        font-size: 16px;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 6px;
        a {
          color: var(--text-color);
          font-size: 16px;
          cursor: pointer;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  &_end {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 33px 20px 0;

    &_year {
      color: #98a2b3;
    }

    &_social {
      cursor: pointer;
      display: flex;
      gap: 20px;
    }
  }

  @media (max-width: 480px) {
    &_top {
      display: flex;
      flex-direction: column;

      &_cards {
        width: 80%;
        margin-bottom: 35px;
      }
    }
    &_end {
      display: flex;
      flex-direction: column-reverse;
      &_social {
        margin-bottom: 20px;
      }
    }
  }
  @media (max-width: 574px) {
    &_top {
      display: flex;
      flex-direction: column;

      &_cards {
        width: 80%;
        margin-bottom: 35px;
      }
    }
    &_end {
      display: flex;
      flex-direction: column-reverse;
      &_social {
        margin-bottom: 20px;
      }
    }
  }
  @media (max-width: 1000px) {
    &_top {
      display: flex;
      flex-direction: column;

      &_cards {
        width: 80%;
        margin-bottom: 35px;
      }
    }
    &_end {
      display: flex;
      flex-direction: column-reverse;
      &_social {
        margin-bottom: 20px;
      }
    }
  }
  @media (max-width: 1024px) {
    &_top {
      display: flex;
      flex-direction: column;

      &_cards {
        width: 80%;
        margin-bottom: 35px;
      }
    }
    &_end {
      display: flex;
      flex-direction: column-reverse;
      &_social {
        margin-bottom: 20px;
      }
    }
  }
}

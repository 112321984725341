.profile-btn{
    background-color: #0E3F41;
    color: #fff;
    border-radius: 6px;
    padding: 12px 18px;
}

.use-appeals{
    width: 938px;
    padding: 25px;
    background-color: #fff;
    border-radius: 12px;
}

.user-chat{
    width: 894px; 
    padding: 25px;
    background-color: #fff;
    border-radius: 12px;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

html[data-theme="light"] {
  --text-color: #121212;
  --text-color-2: #2d3134;
  --text-color-3: #5b5f62;
  --body-bg: #ffffff;
  --green: #0e3f41;
  --white: #ffffff;
  --gray: #a1a1a1;
  --dark-gray: #98a2b3;
  --mid-gray: #797979;
  --bg-gray: rgba(255, 255, 255, 0.8);
  --label: rgba(14, 63, 65, 0.5);
  --bg-light-gray: #f2efe8;
  --bg-carousel-gray: #f2efe8;
  --link-color: #0e3f41;
  --footer-bg: #f3f3f3;
  --page-bg: rgba(252, 251, 248, 0.8);
  --input: #d0d5dd;
}
html[data-theme="dark"] {
  --text-color: #ffffff;
  --text-color-2: #ffffff;
  --text-color-3: #dbdbdb;
  --green: #eabf9f;
  --input: #eabf9f;
  --white: #121212;
  --body-bg: #1f1e1f;
  --bg-carousel-gray: #121212;
  --link-color: #ffffff;
  --footer-bg: #121212;
  --dark-gray: #98a2b3;
  --page-bg: #121212;
  --mid-gray: #ffffff;
  --label: #ffffff;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Space Grotesk", sans-serif;
  list-style: none;
}
body {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: var(--body-bg);
}
#root {
  margin-top: 40px;
  @media screen and (max-width: 1140px) {
    margin-top: 0;
  }
}
a {
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 11px;
}
::-webkit-scrollbar-track {
  width: 11px;
  background-color: var(--bg-light-gray);
}
::-webkit-scrollbar-thumb {
  background-color: var(--green);
  border-radius: 10px;
}
.container {
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 20px;
}
.title {
  color: var(--text-color-2);
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 34px;
  @media screen and (max-width: 1140px) {
    font-size: 38px;
    line-height: normal;
  }
  @media screen and (max-width: 745px) {
    font-size: 32px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
}
.text {
  color: var(--text-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  // @media screen and (max-width: 745px) {
  //   font-size: 14px;
  // }
  // @media screen and (max-width: 576px) {
  //   font-size: 11px;
  // }
}
.home {
  padding-top: 154px;
  @media screen and (max-width: 1140px) {
    padding-top: 80px;
  }
}

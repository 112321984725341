@use "../style/global";

.header {
  padding: 0 20px;
  position: fixed;
  width: 100%;
  z-index: 999;
  .container {
    @media screen and (max-width: 1410px) {
      padding: 0;
    }
  }
  &_navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 34px !important;
    border-radius: 10px;
    backdrop-filter: blur(12px);
    background-color: var(--white);
    transition: 0.3s;
    @media screen and (max-width: 1410px) {
      border-radius: 0;
    }
    &_close {
      padding: 0 47px;
      padding-right: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &_left {
        display: flex;
        align-items: center;
        gap: 20px;
        &_item {
          a {
            color: var(--text-color) !important;
          }
        }
      }

      &_right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        &_sun {
          cursor: pointer;
          fill: var(--text-color);
          margin-right: 10px;
        }
        &_signUp {
          background-color: transparent;
          border: none;
          a {
            display: flex;
            width: 100%;
            height: 100%;
            padding: 14px 20px;
            cursor: pointer;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            border-radius: 10px;
            border: 1px solid var(--green);
            background-color: var(--white);
            color: var(--text-color-2) !important;
          }
        }

        &_register {
          background-color: transparent;
          border: none;
          a {
            display: flex;
            width: 100%;
            height: 100%;
            color: var(--white);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            padding: 14px 20px;
            gap: 10px;
            border-radius: 10px;
            background: var(--green);
            border: 1px solid var(--green);
            cursor: pointer;
          }
        }
      }
    }
  }
  .burger {
    display: none;
    background-color: transparent;
    border: none;
    padding: 10px;
    svg {
      fill: var(--text-color);
      font-size: 20px;
    }
  }

  .none {
    padding: 0 47px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  @media (max-width: 1140px) {
    &_navbar {
      align-items: center;
      .burger {
        display: flex;
      }
      &_close {
        overflow: hidden;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: start;
        text-align: center;
        padding: 64px 0;
        position: absolute;
        width: 100%;
        height: 100vh;
        left: -1500px;
        top: 80px;
        background-color: var(--white);
        transition: 0.3s;
        &_left {
          display: inline-block;
          &_item {
            margin-bottom: 42px;
          }
          &_nav {
            margin-bottom: 20px;
          }
        }
        &_right {
          margin-bottom: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .open {
        left: 0;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: start;
        text-align: center;
        padding-top: 64px;
      }
    }
  }
  @media screen and (max-width: 1410px) {
    padding: 0;
  }
}
.shrink {
  padding: 10px 34px !important;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}
